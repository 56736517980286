export const getPlayerVersion = () => {
  const pathname = window.location.pathname;
  if (pathname?.includes("/v3/")) {
    return 3;
  } else if (pathname?.includes("/v2/")) {
    return 2;
  } else {
    return 1;
  }
};
