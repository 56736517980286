import { lazy, useState } from "react";
import { getPlayerVersion } from "./utils/getPlayerVersion";
import "./styles/App.scss";

const WebPlayerV1 = lazy(() => import("./versions/WebPlayerV1"));
const WebPlayerV2 = lazy(() => import("./versions/WebPlayerV2"));
const WebPlayerV3 = lazy(() => import("./versions/WebPlayerV3"));

const WebPlayer = () => {
  const [version] = useState(getPlayerVersion());
  return version === 3 ? <WebPlayerV3 /> : version === 2 ? <WebPlayerV2 /> : <WebPlayerV1 />;
};

export default WebPlayer;
